<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">
            <Header></Header>
          <section class="fixed mypage-contents-fixed">
            <div class="container">
              <div class="row">
                <MyPageContents class="col-lg-3 col-sm-12" v-on:logout="logout" v-on:delete-account="deleteAccount" :user-data="userData" :validation-errors="deleteAccountValidationErrors"></MyPageContents>
                <div class="col-lg-9 col-sm-12 mx-auto mypage-right-frame">
                  <CompanyForm v-if="companyData" v-on:on-click-send-button="editCompany" :isEdit="true" :company-data="companyData" :validation-errors="validationErrors" :is-changed="isChanged"></CompanyForm>
                </div>
              </div>
            </div>
          </section>
          <Footer></Footer>
        </div>
    </div>
</template>

<script>

    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import CompanyForm from "@/views/company/components/CompanyForm";
    import Vue from 'vue'
    import MyPageContents from "@/views/components/MyPageContents";
    import MyPageMixIn from "@/common/mypage_mixin";
    import JobInfoForm from "@/views/jobinfo/component/JobInfoForm.vue";

    export default {
        name: "EditCompany",
        components: {Footer, Header, CompanyForm, MyPageContents},
        mixins: [MyPageMixIn],
        data: function () {
            return {
                isChanged: false
            }
        },
        methods: {
            async editCompany(body) {
                const response = await this.postApi("company/editCompany", body);
                if (response) {
                    this.isChanged = true;
                    this.userData = response.data.user;
                    Vue.prototype.$user = response.data.user;
                } else {
                    this.isChanged = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>
